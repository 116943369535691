#settings {
	font-size: 17px;
	padding-bottom: 32px;
}

/* Settings */

#settings-logo {
	width: 101%;
	height: 80px;
	margin-top: 24px;
	padding: 8px;
}

#settings label {
	display: flex;
	margin: 16px;
	margin-top: 32px;
	font-weight: bold;
	align-items: center;
}

#settings svg:not(#settings-logo) {
	width: 24px;
	height: 24px;
	margin-right: 16px;
	margin-bottom: 1px;
	fill: var(--color-fore-dark);
}

#settings select,
#settings section {
	width: 100%;
	padding: 12px 14px;
	background-color: var(--color-back-darker);
	font-size: 15px;
}

#settings section:nth-last-of-type(2) {
	padding: 24px 14px;
}

#settings h1 {
	margin-top: 24px;
	margin-bottom: 6px;
}

#settings h1:first-of-type {
	margin-top: 0;
}

.settings-first_party {
	color: dodgerblue;
}

.settings-third_party {
	color: orangered;
}

#settings small {
	color: var(--color-fore-light);
	font-size: 12px;
}
