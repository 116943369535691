* {
	box-sizing: border-box;
	outline: none;
}

html,
body,
#app {
	height: 100%;
}

body {
	display: flex;
	margin: 0;
	justify-content: center;
	align-items: center;
	user-select: none;
	overflow: hidden;
}

/* Reset */

input,
select {
	background-color: transparent;
	color: inherit;
	border: none;
	outline: none;
	box-shadow: none;
	font-size: inherit;
	font-weight: inherit;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

select {
	cursor: pointer;
	-webkit-appearance: none;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--color-fore-light);
}

select option {
	background-color: var(--color-back-light);
	color: var(--color-fore-dark);
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

h1 {
	margin: 0;
	padding: 0;
	font-size: inherit;
}

p {
	margin: 0;
}

a {
	color: inherit;
	text-decoration: inherit;
}

svg {
	overflow: visible;
}

/* App */

#app {
	display: flex;
	background-color: var(--color-back-dark);
	color: var(--color-fore-dark);
	font-family: Arial, Helvetica, sans-serif;
	flex-direction: column;
	flex: 1;
	transition: flex-basis .2s cubic-bezier(.25, .1, 0, 1);
	-webkit-tap-highlight-color: transparent;
}

#app[data-theme="light"],
#app[data-theme="dark"] {
	--color-theme: #1e2529;
	--shadow-soft: rgba(0, 0, 0, .12) 0 1px 6px, rgba(0, 0, 0, .12) 0 1px 4px;
	--shadow-hard: rgba(0, 0, 0, .16) 0 3px 10px, rgba(0, 0, 0, .23) 0 3px 10px;
	--anim-fadeIn: view-fade .15s linear forwards;
	--anim-fadeOut: view-fade .15s reverse forwards;
}

#app[data-theme="light"] {
	--color-fore-light: #6a6b70;
	--color-fore-dark: #34393d;
	--color-back-light: white;
	--color-back-dark: #f2f2f2;
	--color-back-darker: #ebebeb;
}

#app[data-theme="dark"] {
	--color-fore-light: #bebebe;
	--color-fore-dark: #eaeaea;
	--color-back-light: #1e2529;
	--color-back-dark: #1b1c1d;
	--color-back-darker: #151516;
}

#app.is-desktop {
	position: relative;
	height: 90%;
	margin: 16px;
	border-radius: 7px;
	box-shadow: var(--shadow-hard);
	flex: 0 1 calc(90vh / 16 * 9);
	overflow: hidden;
}

#app.is-desktop:before {
	z-index: -1;
	content: "";
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: var(--color-theme);
	transition: var(--trans-background);
	filter: brightness(.5);
}

#app[data-path=""] {
	flex-basis: calc(90vh / 9 * 16);
}

/* View */

.view {
	display: flex;
	position: absolute;
	width: 100%;
	height: calc(100% - 64px);
	background-color: var(--color-back-dark);
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
}

.view-empty {
	display: flex;
	width: 100%;
	height: 100%;
	color: var(--color-fore-light);
	font-size: 20px;
	font-weight: bold;
	animation: var(--anim-fadeIn);
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

/* Animations */

@keyframes view-fade {
	
	from {
		opacity: 0;
	}
	
	to {
		opacity: 1;
	}
	
}

/* Queries */

@media (max-width: 512px) {
	
	#app.is-desktop {
		width: 100%;
		height: 100%;
		margin: 0;
		flex: 1;
		border-radius: 0;
	}
	
}
