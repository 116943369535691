#modal-container {
	z-index: 1000;
	display: flex;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .6);
	justify-content: center;
	align-items: center;
}

#modal-container.enter {
	animation: var(--anim-fadeIn);
}

#modal-container.exit {
	animation: var(--anim-fadeOut);
	animation-duration: .25s;
	animation-delay: .125s;
}

/* Modal */

#modal {
	width: 80%;
	max-width: 350px;
	margin-bottom: 48px;
	background-color: var(--color-back-light);
	border-radius: 5px;
	box-shadow: var(--shadow-hard);
	overflow: hidden;
}

#modal-title {
	padding: 12px;
	background-color: var(--color-back-dark);
	border-bottom: 1px solid rgba(127, 127, 127, .2);
	font-size: 20px;
	font-weight: bold;
	text-align: center;
}

/* Modal - Content */

#modal-text {
	padding: 16px;
	color: var(--color-fore-light);
	text-align: center;
	line-height: 1.2;
}

/* Modal - Buttons */

#modal-buttons {
	display: flex;
	color: white;
	font-size: 14px;
	letter-spacing: 1px;
	justify-content: space-around;
}

#modal-buttons canvas {
	color: rgba(0, 0, 0, .2);
}

#modal-buttons-cancel,
#modal-buttons-confirm {
	position: relative;
	display: flex;
	min-width: 0;
	height: 36px;
	font-weight: bold;
	justify-content: center;
	align-items: center;
	transition: background-color .25s ease, flex .25s ease;
	flex: 1;
	cursor: pointer;
}

#modal-buttons-cancel:hover,
#modal-buttons-confirm:hover {
	flex: 1 1 25%;
}

#modal-buttons-cancel.is-active,
#modal-buttons-confirm.is-active {
	flex: 1 1 100%;
}

#modal-buttons-cancel.is-active + #modal-buttons-confirm {
	font-size: 0;
}

#modal-buttons-cancel {
	background-color: var(--color-back-dark);
	color: indianred;
	border-top: 1px solid rgba(127, 127, 127, .2);
}

#modal-buttons-confirm {
	background-color: dodgerblue;
}
