#stop {
	display: flex;
	flex-direction: column;
}

/* Info */

#stop-info {
	z-index: 1;
	display: flex;
	position: relative;
	width: 100%;
	height: 72px;
	background-color: var(--color-back-light);
	box-shadow: var(--shadow-soft);
	font-weight: bold;
	text-align: center;
	transition: background-color .15s;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
}

#stop-info span {
	animation: var(--anim-fadeIn);
}

#stop-info-icon {
	position: absolute;
	left: -50px;
	bottom: -32px;
	width: 192px;
	height: 192px;
	padding: 9px;
	opacity: .25;
	transform: rotate(10deg);
}

#stop-info-icon * {
	fill: white;
}

#route-info > span > div {
	display: flex;
	flex-direction: column;
}

#stop-info-name {
	margin-top: -3px;
	color: white;
	font-size: 22px;
}

#stop-info-description {
	margin-top: 3px;
	color: #f2f2f2;
	font-size: 14px;
}

#stop-info-favorite {
	position: absolute;
	right: 0;
	top: 0;
	width: 72px;
	height: 72px;
	padding: 18px;
	fill: #ddd;
	stroke: white;
	stroke-width: 100;
	cursor: pointer;
	opacity: .35;
	transition: opacity .15s;
}

#stop-info-favorite.is-active {
	opacity: .7;
	animation: navbar-favorites .5s linear forwards;
}

#stop-info-favorite path {
	transition: fill .15s, stroke .15s;
}

#stop-info-favorite.is-active path {
	fill: var(--color-view-favorites-light);
	stroke: var(--color-view-favorites-dark);
}

/* Departures */

#stop-departures {
	flex: 1;
	padding: 6px;
	overflow-y: scroll;
}

#stop-departures li {
	padding: 6px 0;
}

#stop-departures li:first-child {
	padding-top: 0;
}

#stop-departures li:last-child {
	padding-bottom: 0;
}

.stop-departures-departure {
	display: flex;
	position: relative;
	height: 80px;
	border-radius: 6px;
	transition: background-color .1s;
	animation: var(--anim-fadeIn);
}

#stop-departures li:not(:last-child) .stop-departures-departure:after {
	content: "";
	position: absolute;
	left: 6px;
	right: 6px;
	bottom: -7px;
	height: 1px;
	background-color: rgba(127, 127, 127, .2);
}

.stop-departures-departure:hover {
	background-color: rgba(127, 127, 127, .1);
}

.stop-departures-departure > * {
	position: absolute;
}

.stop-departures-departure-icon {
	top: 0;
	width: 48px;
	margin-top: 3px;
}

.stop-departures-departure-name,
.stop-departures-departure-countdown {
	font-size: 28px;
}

.stop-departures-departure-name,
.stop-departures-departure-destination {
	font-weight: bold;
}

.stop-departures-departure-name {
	left: 50px;
	top: 12px;
}

.stop-departures-departure-destination {
	left: 10px;
	bottom: 7px;
}

.stop-departures-departure-destination svg {
	width: 16px;
	margin-right: 8px;
	margin-bottom: -2px;
}

.stop-departures-departure-countdown,
.stop-departures-departure-time {
	right: 10px;
}

.stop-departures-departure-countdown {
	top: 10px;
}

.stop-departures-departure-countdown.is-urgent {
	animation: stop-urgent 1s ease infinite;
}

.stop-departures-departure-countdown span {
	font-size: 18px;
}

.stop-departures-departure-countdown svg {
	position: absolute;
	right: -6px;
	top: -1px;
	width: 12px;
	transform: rotate(-15deg);
}

.stop-departures-departure-time {
	bottom: 11px;
	color: var(--color-fore-light);
	font-size: 19px;
}

.stop-departures-departure-time svg {
	width: 16px;
	height: 16px;
	margin-right: 4px;
	margin-bottom: -1px;
}

/* Animations */

@keyframes stop-urgent {
	
	from {
		opacity: 1;
	}
	
	50% {
		opacity: .25;
	}
	
	to {
		opacity: 1;
	}
	
}
