#navbar {
	z-index: 999;
	display: flex;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 64px;
	background-color: var(--color-back-light);
	box-shadow: var(--shadow-hard);
	justify-content: center;
	cursor: pointer;
}

#navbar-banner {
	z-index: 999;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 48px;
	background-repeat: round;
	filter: drop-shadow(0 -2px 3px rgba(0, 0, 0, .16)) drop-shadow(0 1px 1px rgba(0, 0, 0, .16));
	transform: translateY(-50%);
	pointer-events: none;
}

#navbar ul {
	display: flex;
	width: 100%;
	height: 100%;
	transition: width .3s ease;
}

#app.is-desktop #navbar ul {
	width: calc(90vh / 16 * 9);
}

.navbar-item {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	flex: 1;
}

.navbar-item svg {
	width: 32px;
	transition: width .15s ease;
}

.navbar-item:hover svg {
	width: 38px;
}

/* Animations */

@keyframes navbar-search {
	
	50% {
		transform: scaleX(-1);
	}
	
	to {
		transform: rotateY(1);
	}
	
}

@keyframes navbar-favorites {
	
	25% {
		transform: scale(1.1);
	}
	
	50% {
		transform: scale(1);
	}
	
	75% {
		transform: scale(1.1);
	}
	
	to {
		transform: scaleX(1);
	}
	
}

@keyframes navbar-map {
	
	50% {
		transform: scaleX(.6);
	}
	
	to {
		transform: scaleX(1);
	}
	
}

@keyframes navbar-settings {
	
	to {
		transform: rotate(180deg);
	}
	
}
