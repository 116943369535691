#map {
	position: relative;
	overflow: hidden;
}

#map-leaflet {
	z-index: 0;
	width: 100%;
	height: 100%;
}

/* Message */

#map-message {
	position: absolute;
	top: 32px;
	width: 100%;
	padding: 8px;
	background-color: rgba(0, 0, 0, .45);
	color: white;
	box-shadow: var(--shadow-soft);
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	pointer-events: none;
}

#app[data-theme="dark"] #map-message {
	background-color: rgba(127, 127, 127, .45);
}

#map-message:empty {
	opacity: 0;
}

/* Locate */

#map-locate {
	display: flex;
	position: absolute;
	right: -48px;
	bottom: 16px;
	width: 48px;
	height: 48px;
	padding: 8px;
	background-color: var(--color-back-light);
	border: 0 solid var(--color-view-map-light);
	border-radius: 100%;
	box-shadow: var(--shadow-soft);
	font-size: 28px;
	transition: right .25s ease, border-width .1s;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

#map-locate.is-visible {
	right: 16px;
}

#map-locate.is-active {
	border-width: 3px;
}

/* Leaflet */

.leaflet-control-attribution {
	padding-bottom: 2px;
	background-color: transparent !important;
	color: dodgerblue !important;
	border-bottom-left-radius: 3px;
	font-weight: bold;
	opacity: .75;
}

.leaflet-control-attribution a {
	color: inherit !important;
	font-size: 12px;
}

.leaflet-container {
	background: var(--color-back-dark) !important;
}
