#search {
	display: flex;
	flex-direction: column;
}

/* Top */

#search-top {
	z-index: 1;
	background-color: var(--color-back-light);
	box-shadow: var(--shadow-soft);
	flex: 0;
}

#search-top-input {
	width: calc(100% - 32px);
	margin: 16px;
	margin-bottom: 0;
	padding: 12px 49px;
	background-color: var(--color-back-dark);
	color: var(--color-fore-dark);
	border-radius: 12px;
	font-size: 16px;
}

#search-top-input + svg {
	position: absolute;
	left: 30px;
	top: 25px;
	width: 24px;
}

#search-top-input + svg * {
	transition: stroke .15s ease-in-out;
}

#search-top-input + svg path,
#search-top-input + svg + svg path {
	stroke: #b3b3b3;
}

#search-top-input + svg circle {
	stroke: #bdbdbd;
}

#app[data-theme="dark"] #search-top-input + svg path,
#app[data-theme="dark"] #search-top-input + svg + svg path {
	stroke: #606060;
}

#app[data-theme="dark"] #search-top-input + svg circle {
	stroke: #707070;
}

#search-top-input:focus + svg path {
	stroke: var(--color-view-search-dark) !important;
}

#search-top-input:focus + svg circle {
	stroke: var(--color-view-search-light) !important;
}

#search-top-input + svg + svg {
	position: absolute;
	right: 16px;
	top: 16px;
	width: 42px;
	padding: 12px;
	transform: scale(0);
	stroke: #b3b3b3;
	transition: transform .1s ease-out;
	cursor: pointer;
}

#search-top-input:not(:invalid) + svg + svg {
	transition: transform .15s ease-out;
	transform: scale(1);
}

#search-top-types {
	font-size: 17px;
	font-weight: bold;
	cursor: pointer;
}

.search-top-types-item {
	display: inline-block;
	position: relative;
	width: 50%;
	padding: 16px;
	transition: color .1s;
	text-align: center;
}

.search-top-types-item.is-active {
	color: var(--color-view-search-light);
}

/* Results */

.search-results {
	flex: 1;
	padding: 6px;
	overflow-y: auto;
}

.search-results li {
	padding: 6px 0;
}

.search-results li:first-child {
	padding-top: 0;
}

.search-results li:last-child {
	padding-bottom: 0;
}

.search-results-result {
	display: flex;
	position: relative;
	height: 72px;
	border-radius: 6px;
	font-weight: bold;
	transition: background-color .1s;
	animation: var(--anim-fadeIn);
	align-items: center;
}

.search-results-result:hover {
	background-color: rgba(127, 127, 127, .1);
}

.search-results li:not(:last-child) .search-results-result:after {
	content: "";
	position: absolute;
	left: 6px;
	right: 6px;
	bottom: -7px;
	height: 1px;
	background-color: rgba(127, 127, 127, .2);
}

.search-results-result-distance {
	right: 0;
	padding: 16px;
	font-size: 18px;
}

/* Results - Stops */

.search-results-result[data-type="stop"] .search-results-result-icon {
	width: 72px;
	height: 72px;
	padding: 10px;
	flex-shrink: 0;
}

.search-results-result[data-type="stop"] .search-results-result-icon + div {
	padding-left: 6px;
	flex: 1;
}

.search-results-result[data-type="stop"] .search-results-result-name {
	margin-bottom: 3px;
	font-size: 21px;
}

.search-results-result[data-type="stop"] .search-results-result-description {
	margin-bottom: 1px;
	font-size: 15px;
}

/* Results - Routes */

.search-results-result[data-type="route"] {
	height: 80px;
}

.search-results-result[data-type="route"] > * {
	position: absolute;
}

.search-results-result[data-type="route"] .search-results-result-icon {
	top: 0;
	width: 48px;
	margin-top: 3px;
}

.search-results-result[data-type="route"] .search-results-result-name,
.search-results-result[data-type="route"] .search-results-result-countdown {
	font-size: 28px;
}

.search-results-result[data-type="route"] .search-results-result-name,
.search-results-result[data-type="route"] .search-results-result-destination {
	font-weight: bold;
}

.search-results-result[data-type="route"] .search-results-result-name {
	left: 50px;
	top: 12px;
}

.search-results-result[data-type="route"] .search-results-result-destination {
	left: 10px;
	bottom: 7px;
}

.search-results-result[data-type="route"] .search-results-result-destination svg {
	width: 16px;
	margin-right: 8px;
	margin-bottom: -2px;
}
