#route {
	display: flex;
	flex-direction: column;
}

/* Info */

#route-info {
	z-index: 1;
	display: flex;
	position: relative;
	width: 100%;
	height: 72px;
	background-color: var(--color-back-light);
	box-shadow: var(--shadow-soft);
	font-weight: bold;
	text-align: center;
	transition: background-color .15s;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
}

#route-info span {
	animation: var(--anim-fadeIn);
}

#route-info-icon {
	z-index: -1;
	position: absolute;
	left: -50px;
	bottom: -32px;
	width: 192px;
	height: 192px;
	padding: 9px;
	opacity: .25;
	transform: rotate(10deg);
}

#route-info-icon * {
	fill: white;
}

#route-info > span > div {
	display: flex;
	flex-direction: column;
}

#route-info-name {
	margin-top: -3px;
	color: white;
	font-size: 22px;
}

#route-info-description {
	height: 0;
	color: #f2f2f2;
	border-radius: 8px;
	font-size: 14px;
	text-align: center;
	transition: margin-top .15s, padding .15s, background-color .15s;
}

#route-info-description:not(:empty) {
	height: 22px;
	margin-top: 3px;
	padding: 3px 12px;
	background-color: rgba(0, 0, 0, .1);
}

/* Stops */

#route-stops {
	padding: 6px;
	flex: 1;
	overflow-y: auto;
}

#route-stops li {
	padding: 6px 0;
}

#route-stops li:first-child {
	padding-top: 0;
}

#route-stops li:last-child {
	padding-bottom: 0;
}

.route-stops-stop {
	display: flex;
	position: relative;
	height: 72px;
	border-radius: 6px;
	font-weight: bold;
	transition: background-color .1s;
	animation: var(--anim-fadeIn);
	align-items: center;
}

#route-stops li:not(:last-child) .route-stops-stop:after {
	content: "";
	position: absolute;
	left: 6px;
	right: 6px;
	bottom: -7px;
	height: 1px;
	background-color: rgba(127, 127, 127, .2);
}

.route-stops-stop:hover {
	background-color: rgba(127, 127, 127, .1);
}

.route-stops-stop.is-active {
	padding-left: 16px;
	border-left: 2px solid rgba(127, 127, 127, .3);
}

.route-stops-stop-icon {
	width: 72px;
	height: 72px;
	padding: 10px;
	flex-shrink: 0;
}

.route-stops-stop-icon + div {
	padding-left: 6px;
	flex: 1;
}

.route-stops-stop-name {
	margin-bottom: 3px;
	font-size: 21px;
}

.route-stops-stop-description {
	margin-bottom: 1px;
	font-size: 15px;
}

.route-stops-stop-countdown {
	margin-bottom: 8px;
	font-size: 28px;
}

.route-stops-stop-countdown.is-urgent {
	animation: route-urgent 1s ease infinite;
}

.route-stops-stop-countdown.has-arrived {
	color: lightgray;
}

.route-stops-stop-time {
	color: var(--color-fore-light);
	font-size: 19px;
}
