#favorites {
	padding: 8px;
	color: white;
	font-size: 20px;
	font-weight: bold;
}

/* Favorites */

#favorites ol {
	height: 100%;
}

#favorites li {
	padding: 8px;
	pointer-events: none;
}

.favorites-stop {
	display: flex;
	width: 100%;
	height: 80px;
	box-shadow: var(--shadow-soft);
	border: 3px solid transparent;
	border-radius: 3px;
	align-items: center;
	cursor: pointer;
	pointer-events: all;
}

#app[data-theme="dark"] .favorites-stop {
	box-shadow: var(--shadow-hard);
}

#favorites li.is-dragging .favorites-stop {
	border: 3px solid rgba(255, 255, 255, .5);
}

.favorites-stop-icon {
	width: 72px;
	height: 72px;
	padding: 4px;
	flex-shrink: 0;
}

.favorites-stop-icon + div {
	width: 100%;
	margin-left: 3px;
	overflow: hidden;
}

.favorites-stop-name,
.favorites-stop-description {
	margin-right: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.favorites-stop-name {
	margin-bottom: 3px;
	font-size: 21px;
}

.favorites-stop-description:not(:empty) {
	margin-bottom: 3px;
	color: #eee;
	font-size: 15px;
}
