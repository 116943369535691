#status {
	display: flex;
	width: 100%;
	height: 100%;
	color: var(--color-fore-light);
	font-size: 20px;
	font-weight: bold;
	justify-content: center;
	align-items: center;
	animation: var(--anim-fadeIn);
	animation-delay: .3s;
	opacity: 0;
}

.loader-vehicle {
	width: 64px;
	margin: 0 16px;
}

#loader-bus {
	animation: loader-bounce .6s cubic-bezier(.25, .46, .45, .94) infinite;
}

#loader-trol {
	animation: loader-bounce .6s cubic-bezier(.25, .46, .45, .94) infinite .15s;
}

#loader-tram {
	animation: loader-bounce .6s cubic-bezier(.25, .46, .45, .94) infinite .3s;
}

/* Animations */

@keyframes loader-bounce {
	
	50% {
		transform: translateY(-32px);
		animation-timing-function: cubic-bezier(.55, .085, .68, .53);
	}
	
	to {
		transform: translateY(0);
	}
	
}
